
.App {
  text-align: center;
    
}

h1{
  font-size: 24px;
}
.error-message{
  position: relative;
  
  width: 80vw;
  background-color: rgba(var(--lightest),1);
  color: black;
  margin: 10vh auto;
  padding: 1px 10px 20px 10px;
  border-radius: 1rem;
  text-align: center;
  
}
.error-container{
  position: fixed;
  
  background-color: rgba(100, 0, 0, 0.63);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.footer{
  position: relative;
  width: 100vw;
  background-color: rgba(var(--darkest),1); 
  font-size: 15px;
  text-align: center;
  padding: 10px 0
  ;
  bottom: 0px;
}

.footer-link{
  margin: 5px;
  color: rgba(var(--lightest),1);  
}

.link-button{
  margin: 0;
  border: 0px solid black;
  color: rgba(var(--light),1);
  font-size: 24px;
  font-weight: bold;
  vertical-align: normal;
  padding: 0 4px 4px 0px;
}
.dsgvo{
  padding: 5vmin;
  padding-top: 7vh;
  
  background-color: rgba(var(--dark),1);
  color: rgba(var(--lightest),1);
}

.impressum{
  
  padding: 5vmin;
  padding-top: 8vh;
  background-color: rgba(var(--dark),1);
  color: rgba(var(--lightest),1);
  height: 85vh;
}

.share-button{
  vertical-align: bottom;
  padding: 0 20px;
}

.buttonicon{
  pointer-events: none;
}




.controlsContainer{
  color: #3c49be;
}