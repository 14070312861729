.main{
background-color: rgba(0, 0, 0, 0.1);
}

.menu-text{
    position: relative;
    margin-top: 50px;
    margin-bottom: 0vh;
    color: rgba(var(--lightest),1);
    text-align: center;
    /* height: calc(8vmin + 30vmin); */
    width: 90vw;
    border-radius: 0rem;
    padding: 5vw;
    padding-top: 0;
    
    background-color: rgba(0, 0, 0, 0.1);
  }