:root {
  --darkblue: #000638;
  --glowyellow: #fb0;


}

.map{
  background-color: var(--darkblue);
  background-color: rgb(46, 72, 100);
  background-image: linear-gradient(rgb(46, 72, 100), var(--darkblue));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vw);
}

.menu-info{
  color: black;
}


.dnd-image{
  width: 13vw;
  padding: 10px;
}

.image-search{
  width: 100%;
  border-radius: 1rem;
}

.progressbar{
  transition: transform 1s cubic-bezier(.31,2.24,.72,.14);
  stroke: #000638;
  animation: rainbow 3s linear infinite forwards;
  stroke-width: 10px;
}

@keyframes rainbow {
  0%  {stroke: rgba(var(--lightest),1)}
  100%   {stroke: rgba(var(--darker),1)}
}
@keyframes show-path {
  0%   {stroke-dashoffset: 111;}
  100% {stroke-dashoffset: 1;}
}
@keyframes show-trail {
  0%   {stroke-dashoffset: 190;}
  100% {stroke-dashoffset: 20;}
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 111; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  font-size: medium;
  backdrop-filter: blur(8px);
}
@supports not (backdrop-filter: blur(8px)) {
  .modal {
    background: linear-gradient(35deg, #0e161ffc 0%, #1b263bf4 100%); 
  }
}

/* Modal Content/Box */
.modal-content {
  position: relative;
  background-color: #f9f9f9;
  /* background-image: radial-gradient(circle at 70% 50%, rgb(255, 255, 255) 70%, rgba(var(--lighter),1) 100%); */
  
  margin: 5vw auto; /* 15% from the top and centered */
  top: 0;
  padding: 5vw;
  border-radius: 50% 50% 1rem 1rem / 4rem 4rem 1rem 1rem;
  border-radius: 1rem;
  overflow: scroll;
  max-height: calc(100% - 10vw);

  width: 90vw; /* Could be more or less, depending on screen size */
}

.quest-content{

 position: sticky;
 padding-bottom: 5px;
 
}
.quest-content h4{
  margin-bottom: 0;
}

.scrollcontent{
  overflow: scroll;
  border-radius: 1rem;
  max-height: 75vh;
  background-color: #fff;
}

.quest-frame{
  overflow: scroll;
  
}
.holster {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;  
  padding: 0;
  overflow-y: hidden;
  
}
.explainslider{
  position: relative;
  width: 100%;
  /* background-color: #000638; */
  display: flex;
  overflow: auto;
  flex: none;
  flex-flow: row nowrap;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  
  padding: 0;
  
  
}
.explainslide{
  width: 90%;
  text-align: center;
  scroll-snap-align: center;
  flex: none;
  scroll-snap-stop: always;
}
.explainslide img{
  border-radius: .3rem;
  width:  calc(98% - 2px);
  background-color: rgba(var(--darker),1);
  padding: 1px;
}

.explainslide p{
  
  position: absolute;
  text-align: center;
  top: 20%;
  width: 80%;
  margin: auto 5%;
  padding: 0%;
  border-radius: 1rem;
  color: rgba(var(--darker),1);
}

.button-group{
  text-align: center;
}
/* The Close Button */
.close {
  position: absolute;
  color: rgba(var(--light),1);
  
  text-align: center;
  padding: 0 10px;
  top: 0;
  right: 0vw;
  font-size: 40px;
  font-weight: bold;
  z-index: 2;
}
.levelmarker{
  text-align: center;
  float: left;
  
  /* background-color: rgba(var(--lightest),1); */
  border-bottom: solid 1px  rgba(var(--darker),1);
  color: rgba(var(--darker),1);
  width: 20vw;
  margin: 0 auto;
  padding: 2px 0;
  border-radius: 1rem;

}
.pointmarker{
  background-color: rgba(var(--darker),1);
  color: rgba(var(--lightest),1);
  text-align: center;
  width: 20vw;
  margin: 0 auto;
  padding: 2px 0;
  border-radius: 10rem;
}

.hint-button {
  border: none;
  width: 49%;
  color: rgba(var(--lightest),1);
  background-color: rgba(var(--lighter),1);
  padding: 0;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}



.win{
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  z-index: 1001;
  filter: drop-shadow(0 0 5px #eee);
  pointer-events: none;
  
  /* overflow: hidden; */
  animation: winning 3s ease-in-out forwards;
}
.win p{
  text-align: center;
  background-color: rgb(0, 129, 6);
  color: rgba(var(--lightest),1);
  padding: 2px 4px;
  border-radius: 1rem;
}
@keyframes winning {
  0%{
    transform: translate(50%);
    opacity: 0;
  }

  50%{
    transform: translate(100%, 0vw) ;
    opacity: 1;
  }

  100%{
    transform: translate(150%);
    opacity: 0;
  }


  
}

.solution{
  background-color: rgba(var(--lightest),1);
  color: black;
  
  border-radius: 1rem;
  padding: 10px 15px;
}

.solve-button {
  border: none;
  width: 49%;
  color: rgba(var(--lightest),1);
  background-color: rgba(var(--darker),1);
  padding: 10px 0;
  margin: 5px 2% 0 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}


.start-button{
  background-color: rgba(var(--darker),1);
  border: none;
  font-weight: bolder;
  color: rgba(var(--lightest),1);
  padding: 10px 0;
  width: 59%;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.solved-button{
  background-color: rgba(var(--darker),1);
  border: none;
  font-weight: bolder;
  color: rgba(var(--lightest),1);
  padding: 10px 0;
  width: 59%;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 0 0 1rem 1rem;
}

.blurfilter{
  position: relative;
  
  width: 100%;
}

.pause-button{
  -webkit-box-shadow:inset 0px 0px 0px 2px rgba(var(--darker),1);
  -moz-box-shadow:inset 0px 0px 0px 2px rgba(var(--darker),1);
  box-shadow:inset 0px 0px 0px 2px rgba(var(--darker),1);
  background-color: rgba(var(--lightest),1);

  font-weight: bolder;
  color: black;
  padding: 10px 0;
  width: 59%;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}


.delete-button{
  background-color: var(--darkwarning);
  border: none;
  font-weight: bolder;
  color: rgba(var(--lightest),1);
  padding: 10px 0px;
  margin: 5px 2% 0 0;
  width: 39%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.restart-button{
  background-color: var(--glowyellow);
  border: none;
  color: var(--darkblue);
  width: 100%;
  padding: 10px 0;
  margin: 5px 0 0 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.input-text{
  border: none;
  border: solid 2px rgb(0,0,0);
  width: calc(100% - 8px);
  padding: 2px;
  margin-top: .8rem;
  font-size: calc(10px + 2vw);
}

.input-text:focus{
  scroll-margin-bottom: 20px;
  
}


.circle-button-in{
  stroke-width: .5px;
  animation: show-circle-in 2s linear forwards;
}

.circle-button-out{

  stroke-width: .5px;
  animation: show-circle-out 2s linear forwards;
}

@keyframes show-circle-in {
  0%   {
    filter: blur(2px);
    stroke:rgb(255, 187, 0,0);
    fill:rgba(255, 187, 0, 0);}
  100% {
    filter: blur(0px);
    stroke:var(--glowyellow);
    fill:rgba(255, 187, 0, 0.219);}
}
@keyframes show-circle-out {
  0%   {
    filter: blur(0px);
    stroke:var(--glowyellow);
    fill:rgba(255, 187, 0, 0.219);}
  100% {
    filter: blur(2px);
    stroke:rgb(100, 100, 100);
    fill:rgba(100, 100, 100, 0.7);}
}


.pixelated-container{
  position: relative;
  width: 100%;
  border-radius: 1rem;
  overflow: scroll;
  margin-bottom: 1px;
  
} 
.pixelated-image{
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.audio-container{
  position: relative;
}
.audio-button{
  border: 0;
  padding: 0;

}
.audio-image{
  width: calc(100% - 10px);
  border-radius: 50%;
  border: solid 5px rgba(var(--dark),1);
}

.audio-player{
  position: absolute;
  bottom: 10px;
  right: 5vw;
  width: 70vw;
  height: 40px;
}

.info-image{
  width: calc(100% - 10px);
  border-radius: 1rem;
  border: solid 3px rgba(var(--dark),1);
}

.mapformat{
  position: relative;
  top: 0vh;
  
  width: calc(100% - 0px);
  height: 40vh;
  margin-bottom: 10px;
  border: 0px solid black;
  
  
}

.teamname{
  border: solid 0px rgba(var(--darker),1);
  background-color: rgba(var(--lightest), .7);
  color: rgba(var(--dark),1);
  border-radius: 1rem;
  text-align: center;
  width: calc(100% - 4px);
  padding: 2px;
  font-size: larger;
  font-weight: 300;
  margin: 0 auto;
}

.leaflet-container{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  background: rgb(99, 99, 99);
  
  border-radius: 1rem;

}


.tilefilter{
  
   /* filter: blur(0px) grayscale(0) brightness(0.5) contrast(200%); */
  filter:  brightness(0.55) contrast(200%) sepia(60%) grayscale(60%) blur(1px);

  }

.leaflet-container .leaflet-control-attribution {
  background: rgba(var(--lightest),1);
  background: rgba(255, 255, 255, 0.1);
  font-size: xx-small;
  margin: 0;
  content:""
}

img.leaflet-tile{
  transform: scale(0.9);
}

.arrow{
  position: absolute;
  top: 20px;
  width: 20vw;
  
  right: 0vw;
  z-index: 1000;
}

.refresh-button{
  position: absolute;
  bottom: 15px;
  
  right: 15px;
  z-index: 1000;
}
.refresh-button img{
  width: 40px;
}

 .leaflet-marker-icon {
 filter: drop-shadow(0 0 20px rgba(255,196,0,1));
} 

.mag-area{
  position: relative;
  width: 90vw;
  height: 90vw;
  margin-bottom: 4px;
}

.mag-scroll{
  position: absolute;
  width: calc(90vw - 2px);
  height: calc(90vw - 2px);
  overflow: scroll;
  
  border-radius: 1rem;
}

.mag-image{

  width: 300vw;
  
  
}

.zoom-image{
  width: 100%;
}

.mag-glass{
  position: absolute;
  top: 0vw;
  left: 0vw;
  width: calc(90vw - 0px);
  height:  calc(90vw + 0px);
  
  background-color: #0a0a0ae5;
  mask-image: radial-gradient(35vw at 50% 50%, transparent 80%, black 100%);

border-radius: 1rem;

  
  pointer-events: none;
}


.selection-list{
  /* background-color: #1b263ba8; */
  background-image: linear-gradient(90deg, rgba(var(--lightest),1) 0%, rgba(var(--dark),1) 5%, rgba(var(--dark),1) 95%, rgba(var(--lightest),1) 100%);
  
  color: rgba(var(--lightest),1);
  margin-bottom: 10px;
  padding: 20px 20px;
  border-radius: 0rem;
}

.selection-list input, label{
  margin: 15px 5px;
  
  vertical-align: 0px;
}