

.mapmenu{
  position: relative;
  top: 50px;
  
  width: calc(100vw - 0px);
  height: calc(100vh - 50px);
  margin-bottom: 10px;
  border: 0px solid black;
  z-index: 0;
  
  
}


.leaflet-container .leaflet-control-attribution {
  background: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0.1);
  font-size: xx-small;
  margin: 0;
  content:""
}

.leaflet-tile {
  
  /* filter:  grayscale(1) brightness(0.9) contrast(200%); */
}

.map-overlay{
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}


.leaflet-marker-icon {
  filter: none;
 } 
 .movingmarker {
   filter: drop-shadow(0 0 10px rgba(var(--lightest),1));
   transition: all ease-in-out 3s;
   transition-delay: 0s;
   z-index: 900 !important;
  } 

.vignetteoverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  background: radial-gradient(circle at 50% 50%, transparent 20%, rgba(var(--darkest),1) 90%);
  z-index: 10000;
  pointer-events: none;
}