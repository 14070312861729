

.navbar{
  position: fixed;
  top: 0vh;
  width: 100vw;
  padding: auto;
  text-align: center;
  background-color: rgba(var(--darkest),1);
  color: rgba(var(--lightest),1);
  height: 50px;
  z-index: 10;
  font-size: 0;
  
  overflow: auto;
  white-space: nowrap;
}
.navbar img{
  height: 42px;
  padding: 4px;
  margin: 0;
}
.sidebar{
  position: fixed;
  top: 0;
  right: 0;
  width: 120vw;
  height: 100vh;
  background-color: rgba(var(--darkest),0.6);
  background: linear-gradient(90deg, transparent 0%, rgba(var(--darkest),0.6) 10% );
  color: rgba(var(--lightest),1);
  white-space: normal;
  z-index: 100;
  backdrop-filter: blur(8px);
  

  
  transition: right 0s ease;
}
@supports not (backdrop-filter: blur(8px)) {
  .sidebar {
    background-color: rgba(var(--darkest),0.9);
    
  background: linear-gradient(80deg, transparent 0%, rgba(var(--darkest),0.95) 10% );
  }
}

.sidebar-content{
  
  position: fixed;
  margin-top: 10vh;
  top: 0;
  right: 0;
  width: 65vw;
  height: 100vh;
  padding-left: 15vw;
  padding-top: 20px;
  
  background-image: linear-gradient(87deg, transparent 20%, rgba(var(--darkest),1) 20%, rgba(var(--darker),1) 100%);
  /* background-color: rgba(var(--dark),1); */
  /* background-image: radial-gradient(ellipse at 200% 0%, rgba(var(--darker),1) 10%, rgba(var(--dark),1) 10%, rgba(var(--dark),1) 90%); */
  transform: translateX(00vw);
  transition: all .7s ease;
}

.sidebar-list{
  /* transform: skew(0deg, 0deg); */
  transform-origin: top left;
  padding-left: 5vw;
  transition: transform 1s ease;
  
  height: 100vh;
  background-color:rgba(var(--darker),1);
  background-image: linear-gradient(90deg, rgba(var(--darker),1) 0%, rgba(var(--darker),1) 100%);
  
  overflow: scroll;
}

.sidebar-list a:nth-child(n){
  transform: skew(-3deg, 0deg);
}

.sidebar-button{
  position: absolute;
  color: rgba(var(--lightest),1);
  vertical-align: middle;
  font-size: 48px;
  right: 0;
  padding: 0;
  margin: 0;
  height: 50px;
}

.running-button{
  position: absolute;
  display: inline;
  color: rgba(var(--lightest),1);
  font-size: 12px;
  left: 0;
  padding: 0;
  margin: 0;
  height: 50px;
}

.language-button{
  font-size: larger;
  left: 0;
  padding: 0;
  border: 1px solid rgba(var(--lightest),1);
  margin: 15px;
}

.deutsch{
  
  padding: 5px;
  color: rgba(var(--darkest),1);
  background-color: rgba(var(--lightest),1);
}

.english{
  
  padding: 5px;
  color: rgba(var(--lightest),1);
}

.question{
  
  position: fixed;
  top: 83vh;
  left: 2vw;
  width: 20vw;
  height: 20vw;
  border: rgba(var(--lightest),1) solid 0px;
  background-color: #e0e1dd8a;
  border-radius: 10vw;
  text-align: center;
  font-size: 18vw;
 
  color: aliceblue;
}

div.sidebar a {
  display: block;
  border-right: rgba(var(--darkest),0.6) solid 0px;
  color: rgba(var(--lightest),1);
  text-align: left;
  font-size: larger;
  padding: 20px;
  text-decoration: none;
}

.share-button{
  vertical-align: bottom;
  padding: 20px 20px;
}
