:root {
  --darkblue: #000638;
  --glowyellow: #fb0;
}

.map-character{

  /* background-image: linear-gradient(rgb(46, 72, 100), var(--darkblue)); */
  /* min-height: 100vh; */
  display: flex;
/*   flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
 /*  width: 100% */
   

 height: 100vh;
 width: 100vw;
 overflow-y: scroll;
 overflow-x: hidden;
}



.menu-info{
  color: black;
}

path:not(:root) {
  overflow: visible;
} 
.path-outline{
  width: 100vmin;
  margin: 0vmin;
  /* filter: drop-shadow(0 0 5rem rgb(255, 145, 0)); */

  animation: show-path infinite 5s linear forwards;
}
.path{
  stroke-linecap: round;
  fill:none;
  stroke-dasharray: 1,100;
  stroke-dashoffset: 0;
  stroke-linejoin: round;
  stroke-width:10;
  stroke: var(--glowyellow);
  overflow: visible;
  /* filter: drop-shadow(0 0 5rem rgb(255, 145, 0)); */
  filter: blur(2px);

  /* animation: show-path 5s ease-in forwards; */
}

.path-trail{
  stroke-linecap: round;
  fill:none;
  stroke-dasharray: 20,150;
  stroke-dashoffset: 0;
  stroke-linejoin: round;
  stroke-width:1;
  stroke: rgba(255, 187, 0, 0.699);
  /* filter: drop-shadow(0 0 5rem rgb(255, 145, 0)); */

  animation: show-trail  5s ease-in infinite forwards;
}

.path-step{
  stroke-linecap: round;
  fill:none;
  stroke-dasharray: 5,7;
  stroke-dashoffset: 0;
  stroke-linejoin: round;
  stroke-width:3;
  stroke: rgba(0, 0, 0, 0.4);
 /*  filter: blur(1px); */
  
}

@keyframes show-path {
  0%   {stroke-dashoffset: 111;}
  100% {stroke-dashoffset: 1;}
}
@keyframes show-trail {
  0%   {stroke-dashoffset: 190;}
  100% {stroke-dashoffset: 20;}
}



.button-group{
  text-align: center;
}



.circle-button-in{
  stroke-width: .5px;
  animation: show-circle-in 2s linear forwards;
}

.circle-button-out{

  stroke-width: .5px;
  animation: show-circle-out 2s linear forwards;
}

@keyframes show-circle-in {
  0%   {
    filter: blur(2px);
    stroke:rgb(255, 187, 0,0);
    fill:rgba(255, 187, 0, 0);}
  100% {
    filter: blur(0px);
    stroke:var(--glowyellow);
    fill:rgba(255, 187, 0, 0.219);}
}
@keyframes show-circle-out {
  0%   {
    filter: blur(0px);
    stroke:var(--glowyellow);
    fill:rgba(255, 187, 0, 0.219);}
  100% {
    filter: blur(2px);
    stroke:rgb(100, 100, 100);
    fill:rgba(100, 100, 100, 0.7);}
}

