:root {
  --darkblue: #000638;
  --mediumblue: rgb(46, 72, 100);
  --glowyellow: #fb0;
}

.character-moving{
  position: relative;
  left: 0;
  bottom: 50vh;
  height: 50vh;
  
 display: block;
  transition: transform ease 2s;
}
.game-frame{
  position: relative;
  width: 100vmin;
  height: 100vh;
  overflow-y:hidden;
  overflow-x: hidden;
}
.day{
  background: rgb(255,239,109);
  background: linear-gradient(0deg, rgba(255,239,109,1) 0%, rgba(255,241,144,1) 63%, rgba(255,172,0,1) 100%); 
  background: rgb(255,255,255);
  background: radial-gradient(circle at 50% 50%, rgb(255, 255, 255) 0%, #ced7ff 100%); 

}
.night{
  background-image:  linear-gradient(rgb(10,10,10), grey);
}
.game-scroll{
  position: absolute;
  top: 0;
  left: 0;
}

.right-button{
  position: absolute;
  left: 50vmin;
  width: 50vmin;
  top: 0;
  height: 100vh;
}
.left-button{
  position: absolute;
  left: 0vmin;
  width: 50vmin;
  top: 0;
  height: 100vh;
}
.question-button{
  position: absolute;
  width: 20vh;
  /* bottom: 165vh; */
  top: 70vh;
  opacity: 1;
  
  transition: transform ease 2s;
}
.question-image{
  
  width: 100%;

  z-index: 0;
  mix-blend-mode: normal;
  
  transition: transform ease 2s;
}

.character{
  position: absolute;
  left: 10vmin;
  top: 55vh;
  height: 40vh;
  filter: drop-shadow(0 0 10px rgb(214, 201, 201));
  
 display: block;
  transition: transform ease 2s;
}
.background{
  position: absolute;
  image-rendering: optimizeSpeed;
 opacity: 1;
 top: 0vh;
 max-height: 100vh;
 height: 100vh;
 /* width: 100vmin; */
 margin: 0;
 padding: 0;
 
  object-fit: cover;
  transition: transform ease 2s;
}

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-select:focus {
  outline: none !important;
}

.skyball{
  position: absolute;
  image-rendering: optimizeSpeed;
 opacity: 0.7;
 filter: drop-shadow(0 0 15px #ddd);
 top: 10vh;
 height: 20vh;
 left: 60vmin;
 /* width: 100vmin; */
 margin: 0;
 padding: 0;
 
  object-fit: cover;
  transition: transform ease 2s;
}
.midground{
  position: absolute;
  image-rendering: optimizeSpeed;
 opacity: 1;
 top: 0vh;
 max-height: 100vh;
 height: 100vh;
 /* width: 100vmin; */
 margin: 0;
 padding: 0;
 
  object-fit: cover;
  transition: transform ease 2s;
}

.foreground{
  position: absolute;

  left: 0vmin;
  top: -5vh;
 max-height: 100vh;
 height: 100vh;
 margin: 0;
 padding: 0;
 mix-blend-mode: normal;
 pointer-events: none;
 
  object-fit: cover;
  transition: transform ease 2s;
}

.horizontal-scroll{
  width: 100vmin;
  /* height: 100vh; */
}

.horizontal-scroll-components{
  
  /* height: 100vh; */
  overflow-y: hidden;
}


@keyframes dim-light {
  
  0% {
    fill-opacity:.1;
  }
  50%   {
    fill-opacity:.9;}
  100% {
    fill-opacity:.1;
  }
}
@keyframes rotate-y {
  
  0% {
    transform: rotateZ(10deg) /* rotateY(0deg) */;
  }
  50%   {
    transform: rotateZ(-10deg) /* rotateY(60deg) */;
  }
  100% {
    transform: rotateZ(10deg) /* rotateY(0deg) */;
  }
}

@keyframes scale-x {
  
  0% {
    transform: scale(.95, 1);
  }
  50%   {
    transform: scale(1, .99);
  }
  100% {
    transform: scale(.95, 1);
  }
}


@keyframes translate-x {
  
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  50%   {
    transform: translateX(20px) rotateZ(0deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}

@keyframes negative-translate-x {
  
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  50%   {
    transform: translateX(-20px) rotateZ(0deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}
@keyframes translate-character {
  
  0% {
    transform: translateX(0px) ;
  }
  100% {
    transform: translateX(400px);
  }
}