/* @import url('https://fonts.googleapis.com/css?family=Gideon+Roman');
@import url('https://fonts.googleapis.com/css?family=Playfair+display');
@import url('https://fonts.googleapis.com/css?family=Merriweather');
@import url('https://fonts.googleapis.com/css?family=Lora'); */

@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./fonts/Lora/Lora-VariableFont_wght.ttf) format('truetype');}

:root{

  --bg-color: #ffffff;
  --txt-color: rgb(253, 228, 195);
  
  --bluezero: rgb(1, 7, 14);
  --blueone: rgb(13, 27, 42); /* 0d1b2a */
  --bluetwo: rgb(27, 38, 59);
  --bluethree: rgb(65, 90, 119);
  --bluefour: rgb(119, 141, 169);
  --bluefive: rgb(223, 224, 220);/* dfe0dc */

  --darkone: rgb(28, 28, 28);
  --darktwo: rgb(88, 77, 41);
  --darkfour: rgb(78, 74, 68);
  --darkfour: rgb(253, 228, 195);
  --darkfive: rgb(246, 246, 246);

  --lightest: 223, 224, 220;
  --lighter: 119, 141, 169;
  --light: 65, 90, 119;
  --dark: 27, 38, 59;
  --darker: 13, 27, 42;
  --darkest: 1, 7, 14;

/*   --lightest: 246, 246, 246;
  --lighter: 253, 228, 195;
  --light: 88, 77, 41;
  --dark: 88, 77, 41;
  --darker: 28, 28, 28;
  --darkest: 7, 7, 7; */


  --darkwarning: #900;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}


body {
  margin: 0;
  font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(0deg, rgba(var(--darker),1) 0%, rgba(var(--light),1) 45%, rgba(var(--lighter),1) 100%); */ 

  background-color: rgba(var(--darker),1);
  scrollbar-width: none;  /* Firefox */

}



::-webkit-scrollbar {
  display: none;
}


code {
  font-family: inherit;
}
button {
  font-family : inherit;
}
input{
  
  font-family : inherit;
}

a{
  text-decoration: none;
}

.loading{

  position: absolute;
  top: 7%;
  margin: auto;
  right: 0;
  left: 0;
  height: 30%;
  z-index: 1001;
  animation-name: loading;
  animation-duration: 2s;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  
}
.Nav{
  background-color: rgba(var(--dark),1);
  color: rgba(var(--lightest),1);
  position: sticky;
  /* position: -webkit-sticky; */
  top: 0;
  text-align: center;
  font-size: medium;


}

.codeword{
  height: 80vh;
  text-align: center;
  padding-top: 20vh;
  width: 100vw;
  color: rgba(var(--darker),1);
  background-size: 200% 200%;
/*   background-image: radial-gradient(ellipse at 0% 0%, rgb(0, 75, 189) 0%, rgb(0, 33, 82) 20%, rgb(0, 0, 0) 100%);
  background-image: radial-gradient(ellipse at 0% 0%, rgb(0, 75, 189) 0%, rgb(0, 33, 82) 20%, rgb(0, 0, 0) 100%);
   */
  background-color: rgba(var(--darker),1);
  background-image: 
    linear-gradient(90deg, rgba(var(--lightest),1) 0%, transparent 10%, transparent 90%, rgba(var(--lightest),1) 100%);
   /*  ,
    linear-gradient(0deg, rgba(var(--darker),1) 0%, transparent 10%, transparent 90%, rgba(var(--darker),1) 100%); */
  animation: gradient 8s ease-in-out infinite;
 
/*  background-position: -40% 0% */;
}

.input-code{
  margin: 30vh auto;
  border-radius: .1rem;
  font-size: xx-large;
  width: 70vmin;
  border: none;
  background-color: transparent;
  text-align: center;
  color: rgba(var(--dark),1);
}
.input-code::placeholder{
  border: none;
  color: rgba(var(--dark),1);
}

@keyframes gradient {
  0% {
      background-position: 20% 0%;
  }
  50% {
      background-position: -120% -100%;
  }
  100%{
    background-position: 20% 0%;
  }
  
}
/* .iconcolor{
  filter: hue-rotate(190deg);
} */