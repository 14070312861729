


.main-menu {

  /* background: linear-gradient(35deg, rgba(var(--darker),1) 0%, rgba(var(--light),1) 45%, rgba(var(--lightest),1) 100%); */
  background: linear-gradient(35deg, rgba(var(--lightest),1) 0%, rgba(var(--light),1) 45%, rgba(var(--darker),1) 100%); 
  background: radial-gradient(70vw at 30% 30%, rgba(var(--light),1) 30%, rgba(var(--darker),1) 100%); 


  background-size: 100% 100%;
  
  background-position: center; 
  color: var(--txt-color);
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  background-attachment: fixed;
  background-repeat: no-repeat, no-repeat;
  /* background-size: cover; */
  /* padding: 10vh; */
  
}


.menu-text{
  position: relative;
  margin-top: 50px;
  margin-bottom: 0vh;
  color: rgba(var(--lightest),1);
  text-align: center;
  /* height: calc(8vmin + 30vmin); */
  width: 90vw;
  border-radius: 0rem;
  padding: 5vw;
  padding-top: 0;
  
  background-color: rgba(0, 0, 0, 0.1);
}
.menu-text p{
  text-align: left;
  font-size: medium;
  
}


.menu-info{
  color: var(--txt-color);
  text-align: center;
}



.start-button{
  color: var(--txt-color);
  background-color: var(--bg-color);
  border: 2px rgba(var(--lightest),1) solid;
}

.showmore-button{
  
  font-size: medium;
  
  border-radius: 0rem;
  text-align: center;
  border-bottom: solid 0px rgba(var(--light),1);

  background-color: rgba(var(--darkest),0);
  color: rgba(var(--lightest),1);
  /* mask-image: linear-gradient(to top, transparent 10%, black 60%); */
  padding: 2px 0px;
}

.start-link p{ 
  color: rgba(var(--lightest),1);
  border: 1px solid rgba(var(--light),1);
  border-radius: 2rem;
  padding: 10px 20px;
  width: 30vw;
  margin: auto;
  background-color: rgba(var(--dark),0.6);;
}


.menu-button{
  border: 0px solid black;
  border-radius: 1rem;
  color: rgba(var(--lightest),1);
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  width: 90vw;
}
.menu-open{
  border-radius: 1rem 1rem 0 0;
}
.crop{
  position: relative;
  height: 30vw;
  width: 90vw;
  
  background-color: rgba(89, 0, 255, 0);
  overflow: hidden;
}

.cityname{
  background-color: black;
  padding: 5px;
  font-size: xx-large;
}

.menu-entry{
  position: relative;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: rgba(var(--lightest),1);
  /* height: calc(8vmin + 30vmin); */
  width: 90vw;
}
.menu-locationmenu{
  position: relative;
  border: 0px solid black;
  
  border-radius: 0 0 1rem 1rem;
  text-align: center;
  padding: 5px 0px;
  margin: auto;
  background-color: rgba(var(--darkest),0.5);
  color: rgba(var(--lightest),1);
  
  width: 90vw;
}

.locationimage{
  width: 60vw;
  border-radius: .1rem;
  border: 1px solid rgba(var(--lightest),1);
  margin: 10px;
}

.listitem{
  border: 0px solid rgba(var(--lightest),1);
  border-radius: 1rem;
  width: 66vw;
  margin: 1rem auto;
  padding: 1rem;
  
  
  
  background-color: rgba(var(--lightest),0.1);
  background: linear-gradient(0deg,rgba(var(--lightest),0.2) 0% ,rgba(var(--lightest),0.1) 50%,rgba(var(--lightest),0.2) 100% );
  background: radial-gradient(ellipse at 50% 50%, rgba(var(--lightest),.0) 0%, rgba(var(--lightest),.1) 80%); 
}


.listdisableitem{
  border: 0px solid rgba(var(--lightest),1);
  border-radius: 1rem;
  width: 70vw;
  margin: 1rem auto;
  padding: 1rem;
  
  background-color: rgba(var(--lightest),0.2);
}

.explainimage{
  display: block;
  width: 25vw;
  border-radius: .1rem;
  margin: auto;
  padding: 5px 15px;
  background-color: rgba(var(--darker),0);
  
}

.menu-locationentry{
  
  position: relative;
  padding: 10px 10px 10px 10px;
  width: 80vw;
  margin: 5px auto;
  border-radius: 1rem;
  background-color: rgba(var(--darkest), .5);
  
  color: rgba(var(--lightest),1);
  
}
.menu-locationentry p{
  margin-top: 0;
  /* text-align: center; */
}

.skyline-logo{
  width: 90vw;
  margin: 0;
  display: block;
  filter: drop-shadow(0 0 2px rgba(var(--lightest),1));
}


.skyline-logo:active{
  
  filter: drop-shadow(0 0 0.1rem rgb(0, 0, 0));

}

@media (prefers-reduced-motion: no-preference) {
  .skyline-logo {
    animation: rotate-full infinite 20s linear;
  }
}


@keyframes rotate-full {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}


.infotext{
  background-color:  rgba(var(--darkest), 1);
  color:  rgba(var(--lightest), 1);
  padding: 10vw;
  margin: 0;
  text-align: justify;
  font-size: x-small;
  scroll-snap-stop: always;
}
